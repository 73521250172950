import React from "react"
import Topbar from "../../../components/organisms/topbar"
import CustomLayout from "../../../Layouts/Layout"
import UpdateProductPage from "./UpdateProductPage"
import "../new-product/style.scss"

export default function index({ location }) {
  return (
    <div className="comodity-new-product">
      <Topbar />
      <CustomLayout
        selectedPage={<UpdateProductPage id={location?.state?.id} />}
      />
    </div>
  )
}
